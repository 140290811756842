<script setup>
import VueApexCharts from "vue3-apexcharts";

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  totalClicks: {
    type: Number,
    required: true,
    default: 0,
  },
});

const series = computed(() => props.data.map((e) => e.clicks));

const chartOptions = computed(() => ({
  chart: {
    id: "devices-summary",
    type: "donut",
  },
  title: {
    text: "Devices",
    margin: 20,
    offsetX: 0,
    offsetY: 2,
    style: {
      fontSize: "1rem",
      fontWeight: 700,
      fontFamily: "Avenir, Helvetica, Arial, sans-serif",
    },
  },
  noData: {
    text: "No clicks data recorded",
    align: "center",
    verticalAlign: "middle",
    offsetX: 0,
    offsetY: 0,
    style: {
      color: undefined,
      fontSize: "1rem",
      fontWeight: 700,
      fontFamily: "Avenir, Helvetica, Arial, sans-serif",
    },
  },
  legend: {
    show: true,
    position: "right",
    horizontalAlign: "center",
    floating: true,
    // offsetY: 5
  },
  colors: ["#ff4222", "#E91E63", "#9C27B0", "#64d735", "#22aeff", "#faba02"],
  fill: {
    // type: "gradient",
    colors: ["#F44336", "#E91E63", "#9C27B0", "#64d735", "#22aeff", "#faba02"],
  },

  plotOptions: {
    // radialBar: {
    // 	inverseOrder: true,
    // 	dataLabels: {
    // 		name: {
    // 			fontSize: "22px",
    // 			formatter: function(value: any) {
    // 				return capitalize(value);
    // 			}
    // 		},
    // 		value: {
    // 			fontSize: "16px",
    // 			// offsetY: 1,
    // 			formatter: function(value: any) {
    // 				return ((value / props.totalClicks) * 100).toFixed(2) + "%";
    // 			}
    // 		}
    // 	}
    // hollow: {
    // 	margin: 0,
    // 	size: "5%",
    // 	background: "transparent",
    // 	position: "front",
    // 	dropShadow: {
    // 		enabled: false,
    // 		top: 0,
    // 		left: 0,
    // 		blur: 3,
    // 		opacity: 0.5
    // 	}
    // }
    // track: {
    // 	show: true,
    // 	background: "#f2f2f2",
    // 	strokeWidth: "97%",
    // 	opacity: 1,
    // 	margin: 15,
    // 	dropShadow: {
    // 		enabled: false,
    // 		top: 0,
    // 		left: 0,
    // 		blur: 3,
    // 		opacity: 0.5
    // 	}
    // }
    // }
  },
  labels: props.data.map((e) => e.device),
}));
</script>

<template>
  <div v-if="data?.length > 0" class="p-5">
    <VueApexCharts
      type="donut"
      height="100%"
      width="100%"
      :options="chartOptions"
      :series="series"
    />
  </div>
  <div v-else class="w-full">
    <div class="flex flex-wrap h-full w-full">
      <div class="items-center flex justify-center h-80 w-full">
        <div>
          <SvgoNoAnalyticalData class="mx-auto text-[14rem] pointer-events-none" />
          <h1 class="font-bold text-md mt-5 dark:text-gray-50">
            No clicks data found for devices!
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>
