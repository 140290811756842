<script setup>
import VueApexCharts from "vue3-apexcharts";

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  totalClicks: {
    type: Number,
    required: true,
    default: 0,
  },
});

const formatTooltip = (data, value) => `
  <div class="custom-tooltip p-2 ">
    <div class="text-gray-600 font-medium">
      ${data}
    </div>
    <div class='font-bold'>
      ${value.toFixed(1)}% 
    </div>
  </div>
`;

const series = computed(() => [
  {
    name: "clicks",
    data: props.data.map((e) => (e.clicks / props.totalClicks) * 100) || 0,
  },
]);
const chartOptions = computed(() => ({
  chart: {
    id: "browsers-summary",
    type: "bar",
  },
  title: {
    text: "Browsers",
    margin: 20,
    offsetX: 10,
    offsetY: 2,
    style: {
      fontSize: "1rem",
      fontWeight: 700,
      fontFamily: "Avenir, Helvetica, Arial, sans-serif",
    },
  },
  noData: {
    text: "No clicks data recorded for browser!",
    align: "center",
    verticalAlign: "middle",
    offsetX: 0,
    offsetY: 0,
    style: {
      color: undefined,
      fontSize: "1rem",
      fontWeight: 700,
      fontFamily: "Avenir, Helvetica, Arial, sans-serif",
    },
  },

  plotOptions: {
    bar: {
      horizontal: false,
      // columnWidth: "55%",
      endingShape: "rounded",
      borderRadius: 5,
      borderRadiusApplication: "end",
      dataLabels: {
        formatter: function (value) {
          return value;
        },
      },
      colors: {
        ranges: [
          {
            from: 0,
            to: 100,
            color: "#0059ff",
          },
        ],
      },
    },
  },
  dataLabels: {
    enabled: false,
  },

  xaxis: {
    categories: props.data.map((e) => e.browser),
  },
  yaxis: {
    // max: 100,
    labels: {
      formatter: function (value, opts) {
        return value + "%";
      },
    },
  },

  tooltip: {
    y: {
      formatter: function (value) {
        return value.toFixed(2) + "%";
      },
    },
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      const data = w.globals.labels[dataPointIndex];
      const value = series[seriesIndex][dataPointIndex];

      return formatTooltip(data, value);
    },
  },
  grid: {
    show: true,
    strokeDashArray: 4,
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
}));
</script>

<template>
  <div v-if="data?.length > 0" class="pt-5 px-5 pb-3 w-full">
    <VueApexCharts
      type="bar"
      width="100%"
      height="100%"
      :options="chartOptions"
      :series="series"
    />
  </div>
  <div v-else class="w-full">
    <div class="flex flex-wrap h-full w-full">
      <div class="items-center flex justify-center h-80 w-full">
        <div>
          <SvgoNoAnalyticalData class="mx-auto text-[14rem] pointer-events-none" />
          <h1 class="font-bold text-md mt-5 dark:text-gray-50">
            No clicks data found for browser!
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>
