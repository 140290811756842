<script setup>
import { FilterOperator, FilterMatchMode } from "primevue/api";
import abbreviate from "number-abbreviate";

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  totalClicks: {
    type: Number,
    required: true,
    default: 0,
  },
  isFullScreen: {
    type: Boolean,
    default: false,
  },
});
const deviceFamilyListTable = ref();

const filters = ref({
  device_family: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
});
</script>
<template>
  <DataTable
    :ref="deviceFamilyListTable"
    :value="data"
    dataKey="device_family"
    :paginator="false"
    :auto-layout="false"
    :resizableColumns="true"
    columnResizeMode="fit"
    :scrollable="true"
    responsiveLayout="scroll"
    :scrollHeight="isFullScreen ? '100%' : '20rem'"
    removableSort
    filterDisplay="menu"
    v-model:filters="filters"
    stateStorage="session"
    stateKey="device-family-table-session"
    class="remove-scroll text-gray-500 dark:text-gray-400"
    id="device-family-table"
  >
    <template #empty>
      <h3 class="text-center select-none">No Clicks data found.</h3>
    </template>
    <!--  -->
    <Column
      field="device_family"
      filterField="device_family"
      header="DEVICE FAMILY"
      :sortable="true"
      headerClass="device-family-headerClass"
      bodyClass="bg-gray-50 dark:bg-[#1f1f1f] "
      style="width: 65% !important"
    >
      <template #body="{ data }">
        <div
          class="items-center flex font-medium text-gray-900 dark:text-white dark:bg-[#1f1f1f] text-sm whitespace-nowrap"
        >
          <span class="ml-2">{{ data.device_family }}</span>
        </div>
      </template>

      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          placeholder="Search device family"
        />
      </template>
    </Column>
    <Column
      field="clicks"
      header="CLICKS"
      :sortable="true"
      headerClass="text-sm !text-gray-900 !bg-white dark:!bg[#181818]"
      style="width: 35% !important"
    >
      <template #body="{ data }">
        <span class="text-sm">
          <span class="font-medium mr-1">{{ abbreviate(data.clicks, 1) }}</span>
          <span class="text-xs"
            >({{ ((data.clicks / totalClicks) * 100).toFixed(2) + "%" }})</span
          >
        </span>
      </template>
    </Column>
  </DataTable>
</template>

<style lang="postcss">
.device-family-headerClass {
  @apply text-sm !text-gray-900 bg-gray-50 dark:bg-[#1f1f1f];
}
#device-family-table > .p-datatable-wrapper > table {
  @apply w-full !table-fixed;
}
</style>
