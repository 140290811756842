<script setup>
import VueApexCharts from "vue3-apexcharts";

const props = defineProps({
  data: {
    type: Array,
    default: [],
  },
  totalClicks: {
    type: Number,
    required: true,
    default: 0,
  },
});

const series = computed(() => props.data.map((e) => e.clicks));
const chartOptions = computed(() => ({
  chart: {
    id: "platforms-chart",
    type: "donut",
  },
  title: {
    text: "Platforms",
    margin: 20,
    offsetX: 0,
    offsetY: 2,
    style: {
      fontSize: "1rem",
      fontWeight: 700,
      fontFamily: "Avenir, Helvetica, Arial, sans-serif",
    },
  },
  noData: {
    text: "No clicks data recorded for plaforms",
    align: "center",
    verticalAlign: "middle",
    offsetX: 0,
    offsetY: 0,
    style: {
      color: undefined,
      fontSize: "1rem",
      fontWeight: 700,
      fontFamily: "Avenir, Helvetica, Arial, sans-serif",
    },
  },
  legend: {
    show: true,
    position: "right",
    horizontalAlign: "center",
    floating: true,
  },
  // colors: [
  // 	"#ff4222",
  // 	"#E91E63",
  // 	"#9C27B0",
  // 	"#64d735",
  // 	"#22aeff",
  // 	"#faba02"
  // ],
  // fill: {
  // 	// type: "gradient",
  // 	colors: [
  // 		"#F44336",
  // 		"#E91E63",
  // 		"#9C27B0",
  // 		"#64d735",
  // 		"#22aeff",
  // 		"#faba02"
  // 	]
  // },

  plotOptions: {},
  labels: props.data.map((e) => e.platform),
}));
</script>

<template>
  <div v-if="data?.length > 0" class="p-5 w-full">
    <VueApexCharts
      type="donut"
      width="100%"
      height="100%"
      :options="chartOptions"
      :series="series"
    />
  </div>
  <div v-else class="w-full">
    <div class="flex flex-wrap h-full w-full">
      <div class="items-center flex justify-center h-80 w-full">
        <div>
          <SvgoNoAnalyticalData class="mx-auto text-[14rem] pointer-events-none" />
          <h1 class="font-bold text-md mt-5 dark:text-gray-50">
            No clicks data found for platforms!
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>
