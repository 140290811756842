<script setup>
import { FilterOperator, FilterMatchMode } from "primevue/api";
import { useAnalyticsStore } from "~/store/useAnalyticsStore.js";
import abbreviate from "number-abbreviate";
import { extractDomain } from "~/utils/urls.js";

const props = defineProps({
  data: {
    type: Array,
    default: [],
  },
  totalClicks: {
    type: Number,
    required: true,
    default: 0,
  },
  isFullScreen: {
    type: Boolean,
    default: false,
  },
});

const domainsListTable = ref();
const domainDictionary = computed(() => {
  const dictionary = {};

  props.data.forEach((item) => {
    const { clicks } = item;
    const domain = extractDomain(item.referral);

    if (domain) dictionary[domain] = (dictionary[domain] || 0) + clicks;
  });

  return dictionary;
});

const formattedDomains = computed(() =>
  Object.entries(domainDictionary.value).map(([domain, clicks]) => ({ domain, clicks }))
);

const filters = ref({
  domain: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
});

const useAnalytics = useAnalyticsStore();
const { addFilter } = useAnalytics;

function addToFilter(value) {
  addFilter("domain", value);
}
</script>

<template>
  <DataTable
    :ref="domainsListTable"
    :value="formattedDomains"
    dataKey="domain"
    :paginator="false"
    :auto-layout="false"
    :resizableColumns="true"
    columnResizeMode="fit"
    :scrollable="true"
    responsiveLayout="scroll"
    :scrollHeight="isFullScreen ? '100%' : '20rem'"
    removableSort
    filterDisplay="menu"
    v-model:filters="filters"
    stateStorage="session"
    stateKey="domains-table-session"
    class="remove-scroll text-gray-500 dark:text-gray-400"
    id="domains-table"
  >
    <template #empty>
      <h3 class="text-center select-none">No Clicks data found.</h3>
    </template>

    <Column
      field="domain"
      filterField="domain"
      header="DOMAIN"
      :sortable="true"
      headerClass="domain-headerClass"
      bodyClass="bg-gray-50 dark:bg-[#1f1f1f] "
      style="width: 65% !important"
    >
      <template #body="{ data }">
        <div
          @click="addToFilter(data.domain)"
          class="items-center flex font-medium text-gray-900 dark:text-white dark:bg-[#1f1f1f] text-sm whitespace-nowrap overflow-x-auto w-full cursor-pointer"
        >
          <div class="socialIcons h-5 w-[7%]">
            <CustomSocialIcons :url="data.domain" :dynamicClass="'!h-5 !w-5'" />
          </div>
          <span class="pl-2 w-[93%] overflow-x-scroll remove-x-scroll">{{
            data.domain
          }}</span>
        </div>
      </template>

      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          placeholder="Search domain"
        />
      </template>
    </Column>
    <Column
      field="clicks"
      header="CLICKS"
      :sortable="true"
      headerClass="text-sm !text-gray-900 !bg-white dark:!bg[#181818]"
      style="width: 35% !important"
    >
      <template #body="{ data }">
        <span class="text-sm">
          <span class="font-medium mr-1">{{ abbreviate(data.clicks, 1) }}</span>
          <span class="text-xs"
            >({{ ((data.clicks / totalClicks) * 100).toFixed(2) + "%" }})</span
          >
        </span>
      </template>
    </Column>
  </DataTable>
</template>

<style lang="postcss">
.domain-headerClass {
  @apply text-sm !text-gray-900 bg-gray-50 dark:bg-[#1f1f1f];
}
.socialIcons > img {
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
#domains-table > .p-datatable-wrapper > table {
  @apply w-full !table-fixed;
}
</style>
