<script setup>
import { TableDomains, TableReferrals, TableDeviceFamily } from "#components";

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  totalClicks: {
    type: Number,
    required: true,
    default: 0,
  },
  header: {
    type: String,
    required: true,
  },
  colKey: {
    type: String,
    required: true,
  },
});

const isDialogVisible = ref(false);

const getTableComponent = () => {
  switch (props.colKey) {
    case "domains":
      return TableDomains;
    case "referrals":
      return TableReferrals;
    case "deviceFamily":
      return TableDeviceFamily;
    default:
      return null;
  }
};
</script>

<template>
  <div
    class="bg-white dark:bg-[#181818] dark:border-[#181818] rounded-xl mr-0 mb-5 w-full overflow-hidden"
  >
    <div
      class="flex items-center w-full justify-between p-7 h-8"
      :class="{ 'hidden h-0': data?.length === 0 }"
    >
      <h1 class="font-bold dark:text-slate-50">{{ header }}</h1>

      <button class="text-sm flex hover:scale-105" @click="isDialogVisible = true">
        <SvgExpand :height="16" :width="16" />
        <span class="ml-1.5">Expand</span>
      </button>
    </div>

    <div class="relative overflow-auto h-full remove-scroll sm:rounded-lg">
      <component
        :is="getTableComponent()"
        :data="data"
        :totalClicks="totalClicks"
        :isFullScreen="false"
      />
    </div>
  </div>

  <Dialog
    :header="header"
    :modal="true"
    :dismissableMask="true"
    :visible="isDialogVisible"
    :maximizable="true"
    @update:visible="isDialogVisible = false"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '45vw' }"
    contentClass="!p-0"
    headerClass="!p-0"
  >
    <component
      :is="getTableComponent()"
      :data="data"
      :totalClicks="totalClicks"
      :isFullScreen="true"
    />
  </Dialog>
</template>

<style></style>
